import { render, staticRenderFns } from "./StaffImportDialog.vue?vue&type=template&id=5b847f1d&scoped=true&"
import script from "./StaffImportDialog.vue?vue&type=script&lang=js&"
export * from "./StaffImportDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b847f1d",
  null
  
)

export default component.exports