<template>
  <el-dialog
    :visible.sync="visible"
    :title="mode ? '新建班级' : '修改班级'"
    class="yt-dialog yt-dialog-default"
    :close-on-click-modal="false"
    :before-close="close"
    @closed="closed"
  >
    <el-form ref="form" :model="formData" label-width="132px" :rules="ruleValidate" class="yt-form yt-dialog-form">
      <el-form-item label="名称" prop="name">
        <el-input v-model="formData.name" placeholder="请输入班级名称" />
      </el-form-item>
    </el-form>
    <div class="footer" slot="footer">
      <el-button class="button" @click="visible = false">取消</el-button>
      <el-button type="primary" :loading="loading" class="button" @click="submit">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import departmentApi from '@api/department'
export default {
  name: 'DepartmentCUDialog',
  data() {
    const validateName = (rule, value, callback) => {
      let reg = /^(?=.*\S).+$/
      if (!reg.test(value)) {
        callback(new Error('班级名称不能全为空格'))
      } else {
        callback()
      }
    }
    return {
      visible: false,
      mode: true,
      loading: false,
      formData: {
        name: '',
        parentId: 0
      },
      ruleValidate: {
        name: [
          { required: true, message: '班级名称不能为空' },
          { max: 20, message: '班级名称长度不能超过20个字符' },
          { min: 1, message: '班级名称长度不小于1个字符' },
          { validator: validateName, trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    initData(row, isParent) {
      if (isParent) {
        this.formData.parentId = row.departmentId
      } else {
        this.formData = {
          name: row.name,
          departmentId: row.departmentId,
          parentId: row.parentId,
          children: row.children
        }
      }
    },
    open(mode) {
      this.visible = true
      this.mode = mode
    },
    submit() {
      if (this.mode) {
        this.create()
      } else {
        this.update()
      }
    },
    create() {
      this.$refs.form.validate(val => {
        if (val) {
          this.loading = true
          departmentApi
            .addDepartment(this.formData)
            .then(res => {
              if (res.code === 0) {
                this.$message.success('新建部门成功')
                let node = {
                  departmentId: res.res,
                  name: this.formData.name,
                  parentId: this.formData.parentId
                }
                this.$emit('close', node)
                this.visible = false
              } else {
                this.loading = false
              }
            })
            .catch(err => {
              this.loading = false
            })
        }
      })
    },
    update() {
      this.$refs.form.validate(val => {
        if (val) {
          this.loading = true
          departmentApi
            .modifyDepartment(this.formData)
            .then(res => {
              if (res.res === true) {
                this.$message.success('修改部门成功')
                let node = { ...this.formData }
                this.$emit('close', node)
                this.visible = false
              } else {
                this.loading = false
              }
            })
            .catch(err => {
              this.loading = false
            })
        }
      })
    },
    close(done) {
      done()
    },
    closed() {
      this.$refs.form.resetFields()
      this.formData.parentId = 0
      this.loading = false
    }
  }
}
</script>

<style lang="less" scoped></style>
