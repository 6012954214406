import config from '../config/config'
import { axios } from './index'
const tagUrl = config.baseUrl + '/tag/api/v1/tag'

export default {
  //获取标签类型
  getAllType() {
    return axios.get(`${tagUrl}/all/type`)
  },
  //分页模糊搜索标签
  searchTag(keyword, typeId, payload) {
    return axios.post(`${tagUrl}/search?keyword=${keyword}&typeId=${typeId}`, payload)
  },
  //根据名称,类型 模糊搜索企业标签
  searchTagByTypeId(payload) {
    return axios.post(`${tagUrl}/search/tags`, payload)
  },
  //添加标签
  addTag(payload) {
    return axios.post(`${tagUrl}/add`, payload)
  },
  //修改标签
  updateTag(payload) {
    return axios.post(`${tagUrl}/update`, payload)
  },
  //批量删除标签
  deleteTags(payload) {
    return axios.post(`${tagUrl}/batch/delete`, payload)
  },
  //判断标签是否有关联对象
  judgeRelation(payload) {
    return axios.post(`${tagUrl}/judge/relation`, payload)
  },
  //获取企业人员标签
  getUserTags() {
    return axios.post(`${tagUrl}/get/user/tags`)
  }
}
