import config from '../config/config'
import { axios } from './index'

const baseUrl = config.baseUrl + '/knowledge/api/v1'

export default {
  getTree(id) {
    return axios.post(baseUrl + `/post/get?parentId=${id}`)
  },
  // getTags() {
  //   return axios.get(baseUrl + '/post/get/root')
  // },
  getTags() {
    return axios.get(baseUrl + '/post/get/root/posts')
  },
  addPost(data) {
    return axios.post(baseUrl + '/post/add', data)
  },
  delPost(id) {
    return axios.get(baseUrl + `/post/delete?postId=${id}`)
  },
  //data里是tabIds字段 后期加上
  editPost(data) {
    return axios.post(baseUrl + `/post/edit/name?postId=${data.parentId}&name=${data.name}&description=${data.description}`, [])
  },
  getAbility(id) {
    return axios.get(baseUrl + `/post/get/ability?postId=${id}`)
  },
  movePost(data) {
    return axios.post(baseUrl + '/post/edit/parent', data)
  },
  addAbility(data) {
    return axios.post(baseUrl + '/ability/add', data)
  },
  postAddAbility(data) {
    return axios.post(baseUrl + '/post/add/ability', data)
  },
  postDelAbility(data) {
    return axios.post(baseUrl + '/post/delete/ability', data)
  },
  getAllAbility(id) {
    return axios.get(baseUrl + `/ability/get?parentId=${id}`)
  },
  editAbility(data) {
    return axios.get(baseUrl + `/ability/edit/name?abilityId=${data.parentId}&newName=${data.name}`)
  },
  delAbility(id) {
    return axios.get(baseUrl + `/ability/delete?abilityId=${id}`)
  },
  getKnowledge(id) {
    return axios.get(baseUrl + `/ability/get/knowledge?abilityId=${id}`)
  },
  moveAbility(data) {
    return axios.post(baseUrl + '/ability/move', data)
  },
  delKnowledge(data) {
    return axios.post(baseUrl + '/ability/delete/knowledge', data)
  },
  addKnowledge(data) {
    return axios.post(baseUrl + '/ability/add/knowledge', data)
  },
  getAllAbilityData() {
    return axios.get(baseUrl + '/ability/getAll')
  },
  getAllPostById(id) {
    return axios.get(baseUrl + `/post/all?postId=${id}`)
  },
  getAllPostDataById(id) {
    return axios.get(baseUrl + `/post/all/data?postId=${id}`)
  }
}
