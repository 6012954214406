<template>
  <el-dialog
    :visible.sync="visible"
    :title="'修改密码'"
    class="yt-dialog yt-dialog-default"
    :close-on-click-modal="false"
    :before-close="close"
    @closed="closed"
  >
    <el-form ref="form" :model="resetPwd" :rules="ruleValidate" label-width="142px" class="yt-form yt-dialog-form">
      <el-form-item label="请输入新密码" prop="pwd">
        <el-input v-model="resetPwd.pwd" type="password" />
      </el-form-item>
      <el-form-item label="请再次输入新密码" prop="pwd1">
        <el-input v-model="resetPwd.pwd1" type="password" />
      </el-form-item>
    </el-form>
    <div class="footer" slot="footer">
      <el-button class="button" @click="visible = false">取消</el-button>
      <el-button type="primary" :loading="loading" class="button" @click="submit">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import staffApi from '@api/staff'
export default {
  name: 'PasswordRestDialog',
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (this.resetPwd.pwd1 !== '') {
          this.$refs.form.validateField('pwd1')
        }
        callback()
      }
    }
    const validatePassCheck = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.resetPwd.pwd) {
        callback(new Error('两次密码不一致，请重新输入'))
      } else {
        callback()
      }
    }
    return {
      ruleValidate: {
        pwd: [{ validator: validatePass, trigger: 'blur' }],
        pwd1: [{ validator: validatePassCheck, trigger: 'blur' }]
      },
      resetPwd: {
        pwd: '',
        pwd1: ''
      },
      visible: false,
      loading: false,
      userId: []
    }
  },
  methods: {
    open(row) {
      this.visible = true
      this.userId = [row.userId]
    },
    //旧功能方法
    submit() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          let payload = {
            userIds: this.userId,
            password: this.resetPwd.pwd
          }
          this.loading = true
          staffApi
            .resetPwd(payload)
            .then(res => {
              if (res.res === true) {
                this.$message.success('修改成功')
                this.$refs['form'].resetFields()
                this.visible = false
              } else {
                this.loading = false
              }
            })
            .catch(err => {
              this.loading = false
            })
        }
      })
    },
    close(done) {
      done()
    },
    closed() {
      this.loading = false
    }
  }
}
</script>

<style scoped></style>
