import { render, staticRenderFns } from "./StaffDepartmentConfigDialog.vue?vue&type=template&id=72c2ddd3&scoped=true&"
import script from "./StaffDepartmentConfigDialog.vue?vue&type=script&lang=js&"
export * from "./StaffDepartmentConfigDialog.vue?vue&type=script&lang=js&"
import style0 from "./StaffDepartmentConfigDialog.vue?vue&type=style&index=0&id=72c2ddd3&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72c2ddd3",
  null
  
)

export default component.exports