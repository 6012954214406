<template>
  <div class="yt-main">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item>系统管理</el-breadcrumb-item>
        <el-breadcrumb-item>组织架构</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="yt-header">
      <el-menu class="yt-menu" :default-active="activeIndex" mode="horizontal" @select="selectMenu">
        <el-menu-item index="0">当前人员</el-menu-item>
        <el-menu-item index="1">已删除人员</el-menu-item>
      </el-menu>
      <div class="yt-header-toolbar">
        <el-input v-model="jobNumber" placeholder="输入学号搜索" class="yt-search" @keydown.enter.native="reSearchStaff">
          <i slot="suffix" class="el-input__icon el-icon-search" @click="reSearchStaff" />
        </el-input>
        <el-input v-model="keyword" placeholder="通过人员名称模糊搜索" class="yt-search" @keydown.enter.native="reSearchStaff">
          <i slot="suffix" class="el-input__icon el-icon-search" @click="reSearchStaff" />
        </el-input>
        <template v-if="activeIndex === '0'">
          <el-button type="primary" size="small" @click="addStaffPersonnel">
            <div>
              <i class="el-icon-circle-plus-outline" />
              <p>新建人员</p>
            </div>
          </el-button>
          <el-button size="small" @click="deleteStaffPersonnel(false)">
            <div>
              <YTIcon :href="'#icon-shanchu'" />
              <p>删除人员</p>
            </div>
          </el-button>
          <el-button size="small" @click="importStaff">
            <div>
              <YTIcon :href="'#icon-daoru1'" />
              <p>导入人员</p>
            </div>
          </el-button>
          <el-button size="small" @click="exportStaff">
            <div>
              <YTIcon :href="'#icon-daochu'" />
              <p>导出人员</p>
            </div>
          </el-button>
        </template>
        <template v-else>
          <el-button type="primary" size="small" @click="toRestorePersonnel(false)">
            <div>
              <i class="el-icon-circle-plus-outline" />
              <p>恢复人员</p>
            </div>
          </el-button>
          <el-button size="small" @click="toDeleteStaffPersonnel(false)">
            <div>
              <YTIcon :href="'#icon-shanchu'" />
              <p>彻底删除</p>
            </div>
          </el-button>
        </template>
      </div>
    </div>
    <div
      class="yt-container"
      :class="{
        'yt-container-flex': activeIndex === '0',
        'is-pack-up': isPackUp
      }"
    >
      <div class="yt-content-tree has-footer" v-if="activeIndex === '0'">
        <template v-if="isPackUp">
          <YTIcon class="pack-up-button" :href="'#icon-zhankai'" @click="isPackUp = false" />
        </template>
        <template v-else>
          <div class="header">
            <YTIcon class="pack-up-button" :href="'#icon-shouqi1'" @click="isPackUp = true" />
            <div>
              <YTIcon style="margin: 0 5px 0 0; font-size: 20px" :href="'#icon-fenlei1'" />
              <p>{{ organizationData.hasOwnProperty('name') ? organizationData.name : '' }}</p>
            </div>
          </div>
          <div class="tree has-tool">
            <el-tree ref="department-tree" :data="departmentData" node-key="departmentId" :highlight-current="true" @node-click="handleNodeClick">
              <div slot-scope="{ node, data }" class="tree-node">
                <el-tooltip effect="dark" :content="data.name" :disabled="data.name.length < 10" placement="top-start">
                  <div class="el-tree-node__label">{{ data.name }}</div>
                </el-tooltip>
                <div v-if="data.departmentId" class="tools">
                  <span class="tool-button" @click.stop="toCreateDepartment(data)">添加</span>
                  <template v-if="data.parentId !== 0">
                    <span class="tool-button" @click.stop="toUpdateDepartment(data)">修改</span>
                    <span class="tool-button" @click.stop="toDeleteDepartment(data)">删除</span>
                  </template>
                </div>
              </div>
            </el-tree>
          </div>
          <div class="footer">
            <el-button style="width: 138px" size="small" @click="toCreateDepartment(false)">
              <div>
                <i class="el-icon-circle-plus-outline" />
                <p>新建班级</p>
              </div>
            </el-button>
          </div>
        </template>
      </div>
      <div class="yt-content">
        <el-table
          ref="staffTable"
          :data="staffList"
          v-loading="staffLoading"
          element-loading-text="加载中"
          class="yt-table"
          height="calc(100% - 35px)"
          @selection-change="selectChange"
        >
          <el-table-column type="expand" width="20px">
            <template slot-scope="props">
              <el-table :data="[props.row]" class="yt-table">
                <el-table-column label="标签" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <div v-if="scope.row.hasOwnProperty('tabVOS') && scope.row.tabVOS !== null && scope.row.tabVOS.length > 0" class="tag-wrapper">
                      <div class="tag-item">{{ scope.row.tabVOS[0].tabName }}</div>
                      <el-popover
                        v-if="scope.row.tabVOS.length > 1"
                        placement="top"
                        title="标签"
                        width="184"
                        trigger="hover"
                        popper-class="yt-table-tag-popover"
                      >
                        <div class="tag-list">
                          <div v-for="(tab, index) in scope.row.tabVOS" :key="index" class="tag-item">
                            {{ tab.tabName }}
                          </div>
                        </div>
                        <div slot="reference" class="dots">
                          <YTIcon :href="'#icon-gengduo1'"></YTIcon>
                        </div>
                      </el-popover>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="性别" show-overflow-tooltip>
                  <template slot-scope="scope">
                    {{ scope.row.gender ? '男' : '女' }}
                  </template>
                </el-table-column>
                <el-table-column label="邮箱" prop="email" show-overflow-tooltip />
                <el-table-column label="手机" prop="phone" show-overflow-tooltip />
              </el-table>
            </template>
          </el-table-column>
          <el-table-column width="31" type="selection" align="center" />
          <el-table-column label="姓名" prop="realName" show-overflow-tooltip />
          <el-table-column label="学号" prop="jobNumber" show-overflow-tooltip />
          <el-table-column label="用户角色" show-overflow-tooltip>
            <template slot-scope="scope">
              <div v-if="scope.row.roleVOS.length > 0" class="tag-wrapper">
                <div class="tag-item">{{ scope.row.roleVOS[0].roleName }}</div>
                <el-popover
                  v-if="scope.row.roleVOS.length > 1"
                  placement="top"
                  title="角色"
                  width="184"
                  trigger="hover"
                  popper-class="yt-table-tag-popover"
                >
                  <div class="tag-list">
                    <div v-for="(tab, index) in scope.row.roleVOS" :key="index" class="tag-item">
                      {{ tab.roleName }}
                    </div>
                  </div>
                  <div slot="reference" class="dots">
                    <YTIcon :href="'#icon-gengduo1'"></YTIcon>
                  </div>
                </el-popover>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="所属班级" show-overflow-tooltip>
            <template slot-scope="scope">
              <div v-if="scope.row.departmentVOS.length > 0" class="tag-wrapper">
                <div class="tag-item department-item">{{ scope.row.departmentVOS[0].name }}</div>
                <el-popover
                  v-if="scope.row.departmentVOS.length > 1"
                  placement="top"
                  title="所属班级"
                  width="184"
                  trigger="hover"
                  popper-class="yt-table-tag-popover"
                >
                  <div class="tag-list">
                    <div v-for="(tab, index) in scope.row.departmentVOS" :key="index" class="tag-item department-item">
                      {{ tab.name }}
                    </div>
                  </div>
                  <div slot="reference" class="dots">
                    <YTIcon class="department-icon" :href="'#icon-gengduo1'"></YTIcon>
                  </div>
                </el-popover>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="专业" show-overflow-tooltip>
            <template slot-scope="scope"> {{ scope.row.postVO.postName }} </template>
          </el-table-column>
          <el-table-column label="招聘方式" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ recruitWayType[scope.row.recruitWay] }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="190px">
            <template slot-scope="scope">
              <template v-if="activeIndex === '0'">
                <span class="tool-button" @click="toConfigRole(scope.row)">配置用户角色</span>
                <span class="tool-button" @click="toConfigDepartment(scope.row)">设定班级</span>
                <el-dropdown placement="bottom-start" @command="handleCommand($event, scope.row)">
                  <span class="tool-button">更多</span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="reset">重置</el-dropdown-item>
                    <el-dropdown-item command="update">修改</el-dropdown-item>
                    <el-dropdown-item command="delete">删除</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
              <template v-else>
                <span class="tool-button" @click="toRestorePersonnel(true, scope.row)">恢复人员</span>
                <span class="tool-button" @click="toDeleteStaffPersonnel(true, scope.row)">彻底删除</span>
              </template>
            </template>
          </el-table-column>
          <div slot="empty" id="empty-slot">
            <template v-if="!staffLoading">
              <YTIcon :href="'#icon-zanwushuju'" style="font-size: 100px"></YTIcon>
            </template>
          </div>
        </el-table>
        <Page
          class="yt-page"
          :class="'yt-page-' + (total.toString().length > 1 ? total.toString().length : 2)"
          :total="total"
          :current="pageNum + 1"
          :page-size-opts="[10, 20, 40, 100]"
          :page-size="pageSize"
          @on-change="changePage"
          @on-page-size-change="changePageSize"
          show-elevator
          show-sizer
          show-total
        />
      </div>
    </div>
    <DepartmentCUDialog ref="departmentCUDialog" @close="toCloseDepartmentCU"></DepartmentCUDialog>
    <ConfirmDialog ref="departmentDeleteDialog" :title="'删除部门'" @on-ok="deleteDepartment">
      <div style="width: 219px; margin: 0 auto;">
        <YTIcon :href="'#icon-jingshi'" style="font-size: 18px;" />
        是否确认删除该部门，删除后将不可恢复
      </div>
    </ConfirmDialog>
    <ConfirmDialog ref="deleteStaffPersonnelDialog" :title="`删除员工`" @on-ok="toDeletePersonnel">
      <div style="width: 219px; margin: 0 auto;">
        <YTIcon :href="'#icon-jingshi'" style="font-size: 18px"></YTIcon>
        确认要删除选中员工吗？
      </div>
    </ConfirmDialog>
    <ConfirmDialog ref="showDeleteConfirm" :title="`删除员工`" @on-ok="clickDelete">
      <div style="width: 219px; margin: 0 auto;">
        <YTIcon :href="'#icon-jingshi'" style="font-size: 18px"></YTIcon>
        彻底删除后则不可恢复 ，还要继续吗？
      </div>
    </ConfirmDialog>
    <ConfirmDialog ref="showRevertConfirm" :title="`恢复员工`" @on-ok="clickRestore">
      <div style="width: 219px; margin: 0 auto;">
        <YTIcon :href="'#icon-jingshi'" style="font-size: 18px"></YTIcon>
        确认要恢复选中员工吗？
      </div>
    </ConfirmDialog>
    <StaffRoleConfigDialog ref="roleConfigDialog" @refresh="searchStaff"></StaffRoleConfigDialog>
    <StaffDepartmentConfigDialog ref="departmentConfigDialog" @refresh="searchStaff"></StaffDepartmentConfigDialog>
    <StaffPersonnelModifyPsd ref="staffPersonnelResetPwdDialog"></StaffPersonnelModifyPsd>
    <StaffCUDialog ref="staffPersonnelCUDialog" @on-success="searchStaff"></StaffCUDialog>
    <StaffImportDialog ref="staffImportDialog" @refresh="searchStaff"></StaffImportDialog>
  </div>
</template>

<script>
import YTIcon from '@components/common/YTIcon'
import DepartmentCUDialog from '@components/common/dialog/DepartmentCUDialog'
import ConfirmDialog from '@components/common/dialog/ConfirmDialog'
import StaffRoleConfigDialog from '@components/common/dialog/StaffRoleConfigDialog'
import departmentApi from '@api/department'
import staffApi from '@api/staff'
import StaffDepartmentConfigDialog from '@components/common/dialog/StaffDepartmentConfigDialog'
import StaffPersonnelModifyPsd from '@components/common/dialog/PasswordRestDialog'
import StaffCUDialog from '@components/common/dialog/StaffCUDialog'
import StaffImportDialog from '@components/common/dialog/StaffImportDialog'
import util from '@/util/util'
export default {
  name: 'StaffList',
  components: {
    StaffImportDialog,
    StaffCUDialog,
    StaffPersonnelModifyPsd,
    StaffDepartmentConfigDialog,
    YTIcon,
    DepartmentCUDialog,
    ConfirmDialog,
    StaffRoleConfigDialog
  },
  data() {
    return {
      recruitWayType: ['校招', '社招'],
      activeIndex: '0', //0:在职人员, 1:已删除人员
      keyword: '',
      departmentId: 0,
      jobNumber: '',
      isPackUp: false, //左侧部门收起
      organizationData: {}, //企业信息
      departmentData: [
        {
          name: '所有人员',
          departmentId: 0
        }
      ],
      departmentProps: {
        label: 'name'
      },
      parentData: {}, //新增子部门存储父节点
      deleteData: {}, //删除部门
      staffList: [],
      organizationId: '',
      departmentIds: [],
      selectedStaff: null,
      showStaffModal: false,
      staffLoading: true,
      staffSelection: [],
      total: 0,
      pageNum: 0,
      pageSize: 10
    }
  },
  mounted() {
    this.getDepartment()
    this.searchStaff()
  },
  methods: {
    //修改员工
    ModifyStaffPersonnel(row) {
      this.$refs.staffPersonnelCUDialog.open(false)
      this.showStaffModal = true
      this.$nextTick(() => {
        this.$refs.staffPersonnelCUDialog.initData(row)
      })
    },
    //新建员工
    addStaffPersonnel() {
      this.$refs.staffPersonnelCUDialog.open(true, this.departmentId === 0 ? this.organizationId : this.departmentId)
    },
    getDepartment() {
      departmentApi.getDepartmentsTree().then(res => {
        this.departmentData = this.departmentData.concat(res.res.children)
        this.organizationData = res.res
        this.organizationId = res.res.departmentId
        this.setCurrentKey()
      })
    },
    getDeletedStaff() {
      const searchStaffDTO = {
        pageNumber: this.pageNum,
        pageSize: this.pageSize,
        realName: this.keyword
      }
      staffApi.getDeletedStaff(searchStaffDTO).then(res => {
        this.staffList = res.res.data
        this.total = res.res.total
      })
    },
    selectMenu(index) {
      this.staffList = []
      this.activeIndex = index
      this.keyword = ''
      this.pageNum = 0
      this.pageSize = 10
      this.total = 0
      this.searchStaff()
    },
    handleNodeClick(data) {
      this.departmentId = data.departmentId
      this.pageNum = 0
      this.pageSize = 10
      this.searchStaff()
    },
    setCurrentKey(departmentId) {
      this.$nextTick(() => {
        this.$refs['department-tree'].setCurrentKey(departmentId ? departmentId : 0)
      })
    },
    searchStaff() {
      if (this.activeIndex === '1') {
        this.departmentId = 0
      }
      this.staffLoading = true
      let searchStaffForm = {
        jobNumber: this.jobNumber,
        keyword: this.keyword,
        departmentId: this.departmentId === 0 ? null : this.departmentId,
        status: parseInt(this.activeIndex)
      }
      staffApi.searchStaffs(searchStaffForm, this.pageNum, this.pageSize).then(res => {
        if (res.res.total > 0 && Math.ceil(res.res.total / this.pageSize) < this.pageNum + 1) {
          this.pageNum = Math.ceil(res.res.total / this.pageSize) - 1
          this.searchStaff()
        } else {
          this.staffList = res.res.data
          this.total = res.res.total
          this.staffLoading = false
        }
      })
    },
    reSearchStaff() {
      this.pageNum = 0
      this.searchStaff()
    },
    //部门增删改查
    toCreateDepartment(data) {
      if (data) {
        this.parentData = data
      } else {
        this.parentData = this.organizationData
      }
      this.$nextTick(() => {
        this.$refs.departmentCUDialog.initData(this.parentData, true)
      })
      this.$refs.departmentCUDialog.open(true)
    },
    toUpdateDepartment(data) {
      this.$nextTick(() => {
        this.$refs.departmentCUDialog.initData(data, false)
      })
      this.$refs.departmentCUDialog.open(false)
    },
    toCloseDepartmentCU(data) {
      if (this.$refs.departmentCUDialog.mode) {
        this.$refs['department-tree'].append(
          data,
          this.parentData.hasOwnProperty('parentId') && data.parentId !== this.organizationData.departmentId ? this.parentData : null
        )
      } else {
        this.updateTreeNode(this.departmentData, data)
      }
      this.parentData = {}
    },
    updateTreeNode(treeNode, data) {
      for (let item of treeNode) {
        if (item.departmentId === data.departmentId) {
          this.$set(item, 'name', data.name)
          this.$forceUpdate()
          break
        }
        if (item.hasOwnProperty('children')) {
          this.updateTreeNode(item.children, data)
        }
      }
    },
    toDeleteDepartment(data) {
      this.deleteData = data
      this.$refs.departmentDeleteDialog.open()
    },
    deleteDepartment() {
      departmentApi.deleteDepartment(this.deleteData).then(res => {
        if (res.res === true) {
          this.$message.success('删除部门成功')
          this.$refs['department-tree'].remove(this.deleteData)
        }
      })
    },
    //表格操作
    toConfigRole(row) {
      //配置用户角色
      this.$refs.roleConfigDialog.open(row)
    },
    //配置部门
    toConfigDepartment(row) {
      this.$refs.departmentConfigDialog.open(row, this.organizationData.name)
    },
    selectChange(val) {
      this.staffSelection = val.map(item => {
        return item.userId
      })
    },
    //彻底删除员工
    toDeleteStaffPersonnel(isSingle, data) {
      if (isSingle) {
        this.staffSelection = [data.userId]
      } else {
        if (this.staffSelection.length === 0) {
          this.$message.warning('请先勾选要彻底删除的员工')
          return
        }
      }
      this.$refs.showDeleteConfirm.open()
    },
    clickDelete() {
      let payload = {
        userIds: this.staffSelection
      }
      staffApi.deleteShiftStaff(payload).then(res => {
        if (res.code === 0) {
          this.$message.success('删除成功')
          this.staffSelection = []
          this.searchStaff()
        }
      })
    },
    //恢复员工
    toRestorePersonnel(isSingle, data) {
      if (isSingle) {
        this.staffSelection = [data.userId]
      } else {
        if (this.staffSelection.length === 0) {
          this.$message.warning('请先勾选要恢复的员工')
          return
        }
      }
      this.$refs.showRevertConfirm.open()
    },
    clickRestore() {
      let payload = {
        userIds: this.staffSelection
      }
      staffApi.restoreStaff(payload).then(res => {
        if (res.code === 0) {
          this.$message.success('恢复成功')
          this.staffSelection = []
          this.searchStaff()
        }
      })
    },
    //删除员工
    toDeletePersonnel() {
      let payload = {
        userIds: this.staffSelection
      }
      staffApi.deleteStaff(payload).then(res => {
        this.$message.success('删除成功')
        this.staffSelection = []
        this.searchStaff()
      })
    },
    handleCommand(command, row) {
      if (command === 'update') {
        this.ModifyStaffPersonnel(row)
      } else if (command === 'reset') {
        this.$refs.staffPersonnelResetPwdDialog.open(row)
      } else if (command === 'delete') {
        this.deleteStaffPersonnel(true, row)
      }
    },
    deleteStaffPersonnel(isSingle, data) {
      if (isSingle) {
        this.staffSelection = [data.userId]
      } else {
        if (this.staffSelection.length === 0) {
          this.$message.warning('请先勾选要删除的员工')
          return
        }
      }
      this.$refs.deleteStaffPersonnelDialog.open()
    },
    importStaff() {
      this.$refs.staffImportDialog.open()
    },
    exportStaff() {
      departmentApi.exportdepartPeo(this.departmentId === 0 ? '' : this.departmentId).then(res => {
        util.exportExamPeoInfo(res, '在职员工')
      })
    },
    changePage(pageNum) {
      this.pageNum = pageNum - 1
      this.searchStaff()
    },
    changePageSize(pageSize) {
      this.pageSize = pageSize
      this.searchStaff()
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/theme/tableDropdownMenu';
.department-item {
  background-color: #effff4 !important;
  color: #4ce47a !important;
}
.tag-wrapper {
  .department-icon {
    fill: #4ce47a !important;
  }
}
</style>
