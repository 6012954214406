<template>
  <el-dialog
    :visible.sync="visible"
    :title="'设置班级'"
    class="yt-dialog yt-dialog-default"
    :close-on-click-modal="false"
    :before-close="close"
    @closed="closed"
  >
    <div>
      <div style="color: #000000">{{ organizationName }}</div>
      <el-tree class="tree" ref="config-tree" :data="treeData" :check-strictly="true" node-key="departmentId" :props="configProps" show-checkbox>
      </el-tree>
    </div>
    <div class="footer" slot="footer">
      <el-button class="button" @click="visible = false">取消</el-button>
      <el-button type="primary" :loading="loading" class="button" @click="setDepartment">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import departmentApi from '@api/department'
import staffApi from '@api/staff'
export default {
  name: 'StaffDepartmentConfigDialog',
  data() {
    return {
      visible: false,
      mode: true,
      userId: '',
      organizationName: '',
      delpartmentIds: [], //默认选择的部门
      loading: false,
      treeData: [],
      configProps: {
        children: 'children',
        label: 'name'
      }
    }
  },
  methods: {
    setDepartment() {
      let departmentIds = this.$refs['config-tree'].getCheckedKeys()
      this.loading = true
      staffApi
        .modifyDepartment(this.userId, departmentIds)
        .then(res => {
          if (res.res === true) {
            this.$message.success('设置班级成功')
            this.$emit('refresh')
            this.visible = false
          } else {
            this.loading = false
          }
        })
        .catch(err => {
          this.loading = false
        })
    },
    getDepartment() {
      departmentApi.getDepartmentsTree().then(res => {
        this.treeData = res.res.children
      })
    },
    open(row, name) {
      this.getDepartment()
      this.organizationName = name
      this.visible = true
      this.userId = row.userId
      let departmentIds = row.departmentVOS.map(item => {
        return item.departmentId
      })
      this.$nextTick(() => {
        this.$refs['config-tree'].setCheckedKeys(departmentIds) //获取已经设置的资源后渲染
      })
    },
    close(done) {
      done()
    },
    closed() {
      this.loading = false
    }
  }
}
</script>

<style lang="less" scoped>
.tree {
  margin-top: 8px;
  height: calc(100% - 80px);
  overflow: auto;
}
::v-deep .el-dialog__body {
  margin-left: 39px !important;
  margin-top: 20px !important;
}
</style>
