<template>
  <el-dialog
    :visible.sync="visible"
    title="导入学生"
    class="yt-dialog yt-dialog-default"
    :close-on-click-modal="false"
    :before-close="close"
    @closed="closed"
  >
    <el-form ref="form" :model="formData" :rules="ruleValidate" label-width="132px" class="yt-form yt-dialog-form">
      <el-form-item label="专业" prop="postId">
        <el-cascader size="small" :props="{ checkStrictly: true, expandTrigger: 'hover' }" :options="postData" v-model="formData.postId" />
      </el-form-item>
      <el-form-item label="班级" prop="departmentId">
        <el-cascader
          size="small"
          :props="{ checkStrictly: true, expandTrigger: 'hover' }"
          :options="departmentData"
          v-model="formData.departmentId"
        />
      </el-form-item>
      <el-form-item label="文件">
        <el-upload action="" accept=".xlsx,.xls" :show-file-list="false" :on-change="handleChange">
          <YTIcon :href="'#icon-daoru1'" />
          请上传excel格式的文件
        </el-upload>
        <a :href="modelDownloadUrl">下载模板</a>
      </el-form-item>
    </el-form>
    <div class="footer" slot="footer">
      <el-button class="button" @click="visible = false">取消</el-button>
      <el-button type="primary" :loading="loading" class="button" @click="submit">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import YTIcon from '@components/common/YTIcon'
import functionApi from '@/api/functionApi'
import config from '@/config/config'
import staff from '@/api/staff'

export default {
  name: 'StaffImportDialog',
  components: { YTIcon },
  data() {
    return {
      visible: false,
      mode: true,
      loading: false,
      formData: {
        postId: '',
        departmentId: ''
      },
      uploadFile: null,
      ruleValidate: {
        postId: { required: true, message: '请选择专业' },
        departmentId: { required: true, message: '请选择班级' }
      },
      postData: [],
      departmentData: []
    }
  },
  computed: {
    modelDownloadUrl() {
      return `${config.baseUrl}/user/api/v1/files/download/excel/auth`
    }
  },
  mounted() {
    this.getPost()
    this.getDepartment()
  },
  methods: {
    open() {
      this.visible = true
    },
    getPost() {
      functionApi.getAllPostDataById(0).then(res => {
        let arr = JSON.stringify(res.res)
        this.postData = JSON.parse(
          arr
            .replace(/posts/g, 'children')
            .replace(/id/g, 'value')
            .replace(/name/g, 'label')
        )
      })
    },
    getDepartment() {
      this.departmentData = []
      this.$store.dispatch('department/getDepartmentTree').then(res => {
        let arr = JSON.stringify(res.res)
        this.departmentData.push(
          JSON.parse(
            arr
              .replace(/children/g, 'children')
              .replace(/departmentId/g, 'value')
              .replace(/name/g, 'label')
          )
        )
        this.handleChildNode(this.departmentData[0])
      })
    },
    handleChildNode(data) {
      data.children.map(item => {
        if (item.children.length === 0) {
          item.children = null
        } else {
          this.handleChildNode(item)
        }
      })
    },
    handleChange(file) {
      this.uploadFile = file
    },
    submit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.uploadFile === null) {
            this.$message.error('请上传文件')
          } else {
            let payload = {
              postId: this.formData.postId[this.formData.postId.length - 1],
              departmentId: this.formData.departmentId[this.formData.departmentId.length - 1]
            }
            this.loading = true
            let formData = new FormData()
            formData.append('file', this.uploadFile.raw, this.uploadFile.name)
            staff
              .importStarff(payload, formData)
              .then(res => {
                if (res.res.failReport === '全部导入成功!') {
                  this.$message.success(res.res.failReport)
                  this.$emit('refresh')
                  this.visible = false
                } else {
                  this.$message.error(res.res.failReport)
                  this.loading = false
                }
              })
              .catch(err => {
                this.loading = false
              })
          }
        }
      })
    },
    close(done) {
      done()
    },
    closed() {
      this.$refs.form.resetFields()
      this.uploadFile = null
      this.loading = false
    }
  }
}
</script>

<style lang="less" scoped></style>
